import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const attackTypes = [
  'DDoS', 'Malware', 'Phishing', 'SQL Injection', 'XSS', 
  'Man-in-the-Middle', 'Zero-Day Exploit', 'Brute Force', 
  'Ransomware', 'Social Engineering', 'Cryptojacking', 
  'DNS Tunneling', 'Password Attack', 'Insider Threat', 'IoT Attack'
];

const attackDescriptions = {
  'DDoS': 'Distributed Denial of Service attack overwhelming servers',
  'Malware': 'Malicious software designed to disrupt or gain unauthorized access',
  'Phishing': 'Fraudulent attempt to obtain sensitive information',
  'SQL Injection': 'Insertion of malicious SQL code for database manipulation',
  'XSS': 'Cross-Site Scripting attack injecting malicious scripts',
  'Man-in-the-Middle': 'Interception of communication between two systems',
  'Zero-Day Exploit': 'Attack on a software vulnerability unknown to the vendor',
  'Brute Force': 'Systematically checking all possible passwords until the correct one is found',
  'Ransomware': 'Malware that encrypts files and demands ransom for decryption',
  'Social Engineering': 'Psychological manipulation to divulge confidential information',
  'Cryptojacking': 'Unauthorized use of a computer to mine cryptocurrency',
  'DNS Tunneling': 'Encoding of data of other programs or protocols in DNS queries',
  'Password Attack': 'Attempts to crack passwords through various methods',
  'Insider Threat': 'Security threat from within the organization',
  'IoT Attack': 'Exploiting vulnerabilities in Internet of Things devices'
};

const locations = [
  { city: 'New York', country: 'USA', coordinates: '40.7128° N, 74.0060° W' },
  { city: 'London', country: 'UK', coordinates: '51.5074° N, 0.1278° W' },
  { city: 'Tokyo', country: 'Japan', coordinates: '35.6762° N, 139.6503° E' },
  { city: 'Sydney', country: 'Australia', coordinates: '33.8688° S, 151.2093° E' },
  { city: 'Berlin', country: 'Germany', coordinates: '52.5200° N, 13.4050° E' },
  { city: 'Paris', country: 'France', coordinates: '48.8566° N, 2.3522° E' },
  { city: 'Moscow', country: 'Russia', coordinates: '55.7558° N, 37.6173° E' },
  { city: 'Beijing', country: 'China', coordinates: '39.9042° N, 116.4074° E' },
  { city: 'Mumbai', country: 'India', coordinates: '19.0760° N, 72.8777° E' },
  { city: 'Sao Paulo', country: 'Brazil', coordinates: '23.5558° S, 46.6396° W' }
];

const generateRandomData = () => {
  return attackTypes.map(attack => ({
    name: attack,
    count: Math.floor(Math.random() * 100)
  }));
};

const generateRandomIP = () => {
  return Array(4).fill(0).map(() => Math.floor(Math.random() * 256)).join('.');
};

const generateRandomLocation = () => {
  return locations[Math.floor(Math.random() * locations.length)];
};

const generateAttackSource = () => {
  const location = generateRandomLocation();
  const ip = generateRandomIP();
  return { message: `Attack detected from ${ip} (${location.city}, ${location.country})` };
};

const generatePredictions = () => {
  const timeframes = ['24 hours', '7 days', '30 days'];
  return timeframes.map(timeframe => ({
    timeframe,
    predictions: Array(3).fill().map(() => {
      const attackType = attackTypes[Math.floor(Math.random() * attackTypes.length)];
      return {
        name: attackType,
        description: attackDescriptions[attackType],
        probability: Math.floor(Math.random() * 100) + '%'
      };
    })
  }));
};

const Card = ({ children, title }) => (
  <div style={{ 
    border: '1px solid #ddd', 
    borderRadius: '8px', 
    padding: '16px', 
    marginBottom: '16px',
    backgroundColor: 'white'
  }}>
    {title && <h2 style={{ marginTop: 0, marginBottom: '16px', color: 'red', fontWeight: 'bold' }}>{title}</h2>}
    {children}
  </div>
);

const TerminalWindow = ({ attackSources, title, textColor = 'green' }) => (
  <Card title={title}>
    <div style={{ 
      backgroundColor: 'black', 
      color: textColor, 
      padding: '16px', 
      fontFamily: 'monospace', 
      height: '200px', 
      overflowY: 'auto' 
    }}>
      {attackSources.map((source, index) => (
        <div key={index}>
          {`[${new Date().toISOString()}] ${source.message}`}
        </div>
      ))}
    </div>
  </Card>
);

const PredictionSection = ({ predictions }) => {
  const [activeTab, setActiveTab] = useState(predictions[0].timeframe);

  return (
    <Card title="Attack Predictions">
      <div>
        {predictions.map((pred, index) => (
          <button 
            key={index} 
            onClick={() => setActiveTab(pred.timeframe)}
            style={{
              padding: '8px 16px',
              marginRight: '8px',
              backgroundColor: activeTab === pred.timeframe ? '#007bff' : '#f8f9fa',
              color: activeTab === pred.timeframe ? 'white' : 'black',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            {pred.timeframe}
          </button>
        ))}
      </div>
      <div style={{ marginTop: '16px' }}>
        {predictions.find(p => p.timeframe === activeTab).predictions.map((attack, idx) => (
          <div key={idx} style={{ marginBottom: '16px' }}>
            <h3 style={{ marginBottom: '8px', color: 'red', fontWeight: 'bold' }}>{attack.name} - {attack.probability} probability</h3>
            <p style={{ fontSize: '14px', color: '#666' }}>{attack.description}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};

const CyberAttackDashboard = () => {
  const [attackData, setAttackData] = useState(generateRandomData());
  const [latestAttack, setLatestAttack] = useState(() => {
    const location = generateRandomLocation();
    return { 
      type: attackTypes[Math.floor(Math.random() * attackTypes.length)], 
      ...location, 
      ip: generateRandomIP() 
    };
  });
  const [threatLevel, setThreatLevel] = useState('Medium');
  const [attackSources, setAttackSources] = useState(() => 
    Array(5).fill(null).map(generateAttackSource)
  );
  const [blockedIPs, setBlockedIPs] = useState(() => 
    Array(3).fill(null).map(generateAttackSource)
  );
  const [predictions, setPredictions] = useState(generatePredictions());

  useEffect(() => {
    const interval = setInterval(() => {
      setAttackData(generateRandomData());
      const newLocation = generateRandomLocation();
      const newIP = generateRandomIP();
      const newAttackType = attackTypes[Math.floor(Math.random() * attackTypes.length)];
      
      setLatestAttack({ type: newAttackType, ...newLocation, ip: newIP });
      setThreatLevel(['Low', 'Medium', 'High'][Math.floor(Math.random() * 3)]);
      
      setAttackSources(prev => [
        { message: `Attack detected from ${newIP} (${newLocation.city}, ${newLocation.country})` },
        ...prev.slice(0, 9)
      ]);
      
      if (Math.random() < 0.3) { // 30% chance to block an IP
        setBlockedIPs(prev => [
          { message: `IP ${newIP} blocked (${newLocation.city}, ${newLocation.country})` },
          ...prev.slice(0, 9)
        ]);
      }

      if (Math.random() < 0.2) {
        setPredictions(generatePredictions());
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ padding: '16px', backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
      <h1 style={{ fontSize: '28px', fontWeight: 'bold', marginBottom: '16px', color: 'red' }}>Predictive Cyber Attack Modeling</h1>
      
      {/* First row: Attack Types and Latest Attack */}
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '16px', marginBottom: '16px' }}>
        <Card title="Attack Types">
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={attackData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
        <Card title="Latest Attack">
          <div style={{ marginBottom: '8px' }}>
            <strong style={{ color: 'red' }}>Type:</strong> {latestAttack.type}
          </div>
          <div style={{ marginBottom: '8px' }}>
            <strong style={{ color: 'red' }}>Location:</strong> {latestAttack.city}, {latestAttack.country}
          </div>
          <div style={{ marginBottom: '8px' }}>
            <strong style={{ color: 'red' }}>Coordinates:</strong> {latestAttack.coordinates}
          </div>
          <div>
            <strong style={{ color: 'red' }}>IP:</strong> {latestAttack.ip}
          </div>
        </Card>
      </div>

      {/* Second row: Attack Sources and Blocked IPs */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px', marginBottom: '16px' }}>
        <TerminalWindow attackSources={attackSources} title="Attack Sources" />
        <TerminalWindow attackSources={blockedIPs} title="Blocked IPs" textColor="red" />
      </div>

      {/* Third row: Threat Level and Attack Predictions */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: '16px' }}>
        <Card title="Threat Level">
          <div style={{
            padding: '16px',
            backgroundColor: threatLevel === 'High' ? '#dc3545' : threatLevel === 'Medium' ? '#ffc107' : '#28a745',
            color: threatLevel === 'Medium' ? 'black' : 'white',
            borderRadius: '4px'
          }}>
            <strong style={{ color: threatLevel === 'Medium' ? 'red' : 'inherit' }}>Current Threat Level:</strong> {threatLevel}
          </div>
        </Card>
        <PredictionSection predictions={predictions} />
      </div>
    </div>
  );
};

export default CyberAttackDashboard;